$(document).ready(function() {
    $('h4').each(function(){
        $(this).nextUntil("h4, h5").wrapAll('<div class="answer" />');
        $(this).addClass('question');
        $(this).addClass('closed');
        $(this).css('cursor','pointer');
    });
    $('.answer').hide(); /* hide all the answers */
    $('h4.question').click(function(){
        $(this).next().toggle();
        $(this).toggleClass('closed');
        $(this).toggleClass('open');
    });
});
